import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 20 min. `}<meta itemProp="prepTime" content="PT20M" /></h5>
    <h5>{`Cook time: 2 hr. `}<meta itemProp="cookTime" content="PT2H" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`6-8`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Whole young chicken, 4-5 lbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter, 3 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 1 head</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Rosemary, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Thyme, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Lemon, 1.5</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Vegetable oil, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt and pepper, to taste</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Dutch oven</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Lemon zester</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Thaw butter to room temperature</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Zest 1/2 lemon</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Slice lemons</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Peel and slice ends of garlic cloves</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Preheat oven to 400F.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Combine butter, rosemary, thyme, lemon zest, salt, and pepper and mix until even.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Line the bottom of the dutch oven with vegetable oil, a few cloves of garlic, and 4 lemon slices.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Evenly rub butter all over, under the skin of the chicken.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Stuff chicken with the remaining lemon and garlic, then place into dutch oven.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cook covered for 30 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Baste chicken.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cook uncovered 35-45 minutes, or until internal temp reaches 160F and the chicken is golden brown. Baste occasionally.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Let rest 10-15 minutes before carving.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      